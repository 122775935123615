<template>
  <section
    v-if="Object.keys(pricing).length"
    id="pricing-plan"
  >
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="mt-5">
        Pricing Plans
      </h1>
      <p class="mb-2 pb-75">
        All plans include 40+ advanced tools and features to boost your product. Choose the best plan to fit your needs.
      </p>
    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <div class="d-flex align-items-center justify-content-center">
      <b-row class="pricing-card">
        <b-col>
          <b-row>
            <b-col md="6">
              <b-card
                align="center"
              >
                <!-- img -->
                <b-img
                  v-if="pricing.basicPlan.img"
                  :src="pricing.basicPlan.img"
                  class="mb-2 mt-5"
                  alt="basic svg img"
                />
                <!--/ img -->
                <h4>{{ pricing.basicPlan.title }}</h4>
                <!-- <b-card-text>
                  {{ pricing.basicPlan.subtitle }}
                </b-card-text> -->

                <!-- annual plan -->
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                    <span class="pricing-basic-value font-weight-bolder text-primary">{{ monthlyPlanShow ? pricing.basicPlan.monthlyPrice:pricing.basicPlan.yearlyPlan.perMonth }}</span>
                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                  </div>
                  <small
                    v-show="!monthlyPlanShow"
                    class="annual-pricing text-muted"
                  >USD {{ pricing.basicPlan.yearlyPlan.totalAnual }} / year</small>
                </div>
                <!--/ annual plan -->

                <!-- plan benefit -->
                <b-list-group class="list-group-circle text-left">
                  <b-list-group-item
                    v-for="(data,index) in pricing.basicPlan.planBenefits"
                    :key="index"
                  >
                    {{ data }}
                  </b-list-group-item>
                </b-list-group>
                <b-form-select
                  :options="acc_type"
                  v-model="sl_type"
                  @change="onChangeAcc"
                />
                <!--/ plan benefit -->

                <!-- buttons -->
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  block
                  class="mt-2"
                  variant="outline-success"
                  @click="buyAccount"
                >
                  Buy Account
                </b-button>
              </b-card>
            </b-col>
            <b-col md="6">
              <b-card
                align="center"
              >
                <!-- img -->
                <b-img
                  v-if="pricing.standardPlan.img"
                  :src="pricing.standardPlan.img"
                  class="mb-2 mt-5"
                  alt="basic svg img"
                />
                <!--/ img -->
                <h4>{{ pricing.standardPlan.title }}</h4>
                <!-- <b-card-text>
                  {{ pricing.basicPlan.subtitle }}
                </b-card-text> -->

                <!-- annual plan -->
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                    <span class="pricing-basic-value font-weight-bolder text-primary">{{ pricing.standardPlan.monthlyPrice }}</span>
                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/ {{ qty == 0.5 ? '500 GB': '1 TB'}}</sub>
                  </div>
                  <small
                    v-show="!monthlyPlanShow"
                    class="annual-pricing text-muted"
                  >USD {{ pricing.standardPlan.yearlyPlan.totalAnual }} / year</small>
                </div>
                <!--/ annual plan -->

                <!-- plan benefit -->
                <b-list-group class="list-group-circle text-left">
                  <b-list-group-item
                    v-for="(data,index) in pricing.standardPlan.planBenefits"
                    :key="index"
                  >
                    {{ data }}
                  </b-list-group-item>
                </b-list-group>

                <!--/ plan benefit -->

                <!-- buttons -->
                <b-form-spinbutton
                  id="sb-large"
                  size="lg"
                  placeholder="1"
                  class="mb-1"
                  :min="0.5"
                  :max="1"
                  v-model="qty"
                  step="0.5"
                  @change="changeQTY"
                />
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  block
                  class="mt-2"
                  variant="outline-success"
                  @click="buyBW"
                >
                  Buy Bandwidth
                </b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing plan cards -->
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      title="Enter Your Membership"
      ok-title="Buy Account"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-input-group
          label="Enter Your Membership"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="membership"
            placeholder="Membership"
            required
          />
          <b-input-group-append>
          <b-button variant="outline-primary" @click="checkMemberShip">
            Check
          </b-button>
        </b-input-group-append>
        </b-input-group>
        <hr>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Membership"
              label-for="h-first-name"
              label-cols-md="4"
            >
              <b-form-input
                id="h-first-name"
                placeholder="First Name"
                v-model="customAcc.membership"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Price"
              label-for="h-email"
              label-cols-md="4"
            >
              <b-form-input
                id="h-email"
                placeholder="Email"
                v-model="customAcc.price"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Total Ports"
              label-for="h-email"
              label-cols-md="4"
            >
              <b-form-input
                id="h-email"
                placeholder="Email"
                v-model="customAcc.ports"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Bandwidth"
              label-for="h-email"
              label-cols-md="4"
            >
              <b-form-input
                id="h-email"
                placeholder="Email"
                v-model="customAcc.bandwidth"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Expired"
              label-for="h-email"
              label-cols-md="4"
            >
              <b-form-input
                id="h-email"
                placeholder="Email"
                v-model="customAcc.expired"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </section>
</template>

<script>
import {
  BFormCheckbox, BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge, BInputGroup,
  BInputGroupAppend, BInputGroupPrepend, BFormInput, BFormSpinbutton, BFormSelect, BModal, VBModal, BFormGroup, BSpinner
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import RepositoryFactory from '../../api/RepositoryFactory'
const PlansRepository = RepositoryFactory.get('plans')

/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormInput,
    BFormSpinbutton,
    BFormSelect,
    BModal,
    BFormGroup,
    BSpinner
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      qty: 0.5,
      sl_type: 25,
      status: 'monthly',
      monthlyPlanShow: true,
      acc_type: [10,25,'Custom'],
      membership: "",
      customAcc: {
        membership: '',
        price: 0,
        expired: 0,
        ports: 0,
        bandwidth: 0
      },
      pricing: {
        basicPlan: {
          title: 'Buy Shadow Account',
          img: require('@/assets/images/illustration/Pot1.svg'),
          subtitle: 'A simple start for everyone',
          monthlyPrice: 350,
          yearlyPlan: {
            perMonth: 350,
            totalAnual: 0,
          },
          planBenefits: [
            '25 Proxy Ports',
            '250 Geo Selection',
            '1 TB Bandwidth'
          ],
          popular: false,
        },
        standardPlan: {
          title: 'Buy Extra Bandwidth',
          img: require('@/assets/images/illustration/Pot1.svg'),
          subtitle: 'For small to medium businesses',
          monthlyPrice: 175,
          yearlyPlan: {
            perMonth: 40,
            totalAnual: 480,
          },
          planBenefits: [
            'Unlimited time',
            '500 GB Bandwidth'
          ],
          popular: false,
        },
      },
    }
  },
  created() {
  },
  methods: {
    dayFormatter(value) {
      return this.qtyv[value]
    },
    tooglePlan() {
      if (this.status === 'monthly') {
        this.monthlyPlanShow = true
      } else {
        this.monthlyPlanShow = false
      }
    },
    changeQTY(){
      if(this.qty == 0.5){
        this.pricing.standardPlan.monthlyPrice = 175
        this.pricing.standardPlan.planBenefits = [
          'Unlimited time',
          '500 GB Bandwidth'
        ]
      }
      if(this.qty == 1){
        this.pricing.standardPlan.monthlyPrice = 300
        this.pricing.standardPlan.planBenefits = [
          'Unlimited time',
          '1 TB Bandwidth'
        ]
      }
    },
    onChangeAcc(){
      if(this.sl_type == 10){
        this.pricing.basicPlan.monthlyPrice = 175
        this.pricing.basicPlan.planBenefits = [
          '10 Proxy Ports',
          '250 Geo Selection',
          '500 GB Bandwidth'
        ]
      }
      if(this.sl_type == 25){
        this.pricing.basicPlan.monthlyPrice = 350
        this.pricing.basicPlan.planBenefits = [
          '25 Proxy Ports',
          '250 Geo Selection',
          '1 TB Bandwidth'
        ]
      }
    },
    buyAccount(){
      if(this.sl_type == "Custom"){
        this.membership = ""
        this.customAcc = {
          membership: '',
          price: 0,
          expired: 0,
          ports: 0,
          bandwidth: 0
        },
        this.$refs['my-modal'].toggle()
      }else{
        this.$swal({
          title: 'Are you sure?',
          text: `Do you want to buy account ${this.sl_type} port for ${this.sl_type == 10 ? 175 : 350 }$?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, buy it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            PlansRepository.buyAccount(this.sl_type).then(rs=>{
              if(rs.data.success){
                this.$store.dispatch('auth/get_uinfo')
                this.$swal({
                  icon: 'success',
                  title: 'Buy Success',
                  text: 'Your can use account now',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }else{
                this.$swal({
                  icon: 'warning',
                  title: 'Error',
                  text: rs.data.errMsg,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
          }
        })
      }
      
    },
    buyBW(){
      let price = this.qty == 0.5 ? 175 : 300
      this.$swal({
        title: 'Are you sure?',
        text: `Do you want to buy ${this.qty} TB for ${price}$?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, buy it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          PlansRepository.buyBW(this.qty).then(rs=>{
            if(rs.data.success){
              this.$store.dispatch('auth/get_uinfo')
              this.$swal({
                icon: 'success',
                title: 'Success',
                text: 'You have 1TB Bandwidth',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }else{
              this.$swal({
                icon: 'warning',
                title: 'Error',
                text: rs.data.errMsg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        }
      })
    },
    checkMemberShip(){
      if(this.membership){
        PlansRepository.getCustomAccount(this.membership).then(rs=>{
          if(rs.data.success){
            this.customAcc = rs.data.data
            this.customAcc.bandwidth = this.formatBytes(this.customAcc.bandwidth)
          }else{
            this.$toast({
                component: ToastificationContent,
                props: {
                  title: rs.data.errMsg,
                  icon: 'AlertOctagonIcon',
                  variant: 'danger',
                },
            });
          }
        })
      }
    },
    formatBytes(bytes) {
      var marker = 1000; // Change to 1000 if required
      var decimal = 2; // Change as required
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1024 KB
      var gigaBytes = marker * marker * marker; // One GB is 1024 MB
      var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
      // return bytes if less than a KB
      if(bytes < kiloBytes) return bytes + " Bytes";
      // return KB if less than a MB
      else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
      // return MB if less than a GB  ``
      else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
      // return GB if less than a TB
      else if(bytes < teraBytes) return (bytes / gigaBytes).toFixed(decimal) + " GB";
      
      else return (bytes / teraBytes).toFixed(decimal) + " TB";
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      PlansRepository.buyAccountMem(this.membership).then(rs=>{
        if(rs.data.success){
          this.$store.dispatch('auth/get_uinfo')
          this.$swal({
            icon: 'success',
            title: 'Buy Success',
            text: 'Your can use account now',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.$nextTick(() => {
            this.$refs['my-modal'].toggle()
          })
        }else{
          this.$swal({
            icon: 'warning',
            title: 'Error',
            text: rs.data.errMsg,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
      // Hide the modal manually
      // this.$nextTick(() => {
      //   this.$refs['my-modal'].toggle('#toggle-btn')
      // })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
